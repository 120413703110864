/* eslint-disable no-self-compare */
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Chip,
  Divider,
  LinearProgress,
  Link,
  Skeleton,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from "@mui/material";
import theme from "lib/theme";
import { Asset } from "types/asset";
import {
  DataGridPro,
  GridColDef,
  GridHeaderSelectionCheckboxParams,
  GridRenderCellParams,
  GridRowId,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import AssetsExportGridItem from "./AssetsExportGridItem";
import { ActionCell } from "./ActionCell";
import SelectAllAssetsBanner from "./SelectAllAssetsBanner";
import AssetsDataGridActions from "./AssetsDataGridActions";
import { formatDate } from "utils/helpers";
import { PickupStatus } from "pages/Pickups/PickupStatus";
import { grey } from "@mui/material/colors";
import { Handyman, Help, Public, Recycling } from "@mui/icons-material";
import useFeatureFlag from "hooks/useFeatureFlag";

type AssetGridProps = {
  replace: (field: string, value: string) => void;
  assets: Asset[];
  isLoading: boolean;
  isError: boolean;
  meta: {
    count: number;
    totalPages: number;
  };
  refetch: () => void;
  setSelectedAssetIds: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  selectedAssetIds: GridSelectionModel;
  setSelectAllFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  selectAllFlag?: boolean;
  assetsTab: string;
  setSecurityLocksOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRetiredAssetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getAllSerialsNumbers: (asset_ids?: GridRowId[], selectAllFlag?: boolean) => Promise<void>;
  fieldsToHide: Array<string>;
};

type ToolbarProps = {
  count: number;
};

const CustomToolbar = ({ count }: ToolbarProps) => {
  return (
    <>
      <GridToolbarContainer sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <AssetsExportGridItem />
        </Box>

        <Typography mr={1} variant="body2" sx={{ color: theme.palette.text.secondary }}>
          {count} Assets Found
        </Typography>
      </GridToolbarContainer>
      <Divider />
    </>
  );
};

const HeaderTooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <Tooltip
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "primary.main",
          },
        },
        arrow: {
          sx: {
            color: "primary.main",
          },
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

const CosmeticConditionTooltip = ({ children }: { children: React.ReactElement<any, any> }) => {
  return (
    <HeaderTooltip
      title={
        <Stack gap={1}>
          Cosmetic condition is the visual condition of the laptop. Defects that determine the condition can include
          dings, scratches, laminate damage, sticker burns, and wear marks.
          <Link color="#fff" href="https://example.com" target="_blank">
            Learn more
          </Link>
        </Stack>
      }
    >
      {children}
    </HeaderTooltip>
  );
};

const FunctionalityFaultTooltip = ({ children }: { children: React.ReactElement<any, any> }) => {
  return (
    <HeaderTooltip
      title={
        <Stack gap={1}>
          Functionality faults are any functional issues or locks found during processing including enrollment, iCloud
          locks, battery issues, and logic board damage. These affect way the asset was processed and its resale value.
          <Link color="#fff" href="https://example.com" target="_blank">
            Learn more
          </Link>
        </Stack>
      }
    >
      {children}
    </HeaderTooltip>
  );
};

const DataSanitizationTooltip = ({ children }: { children: React.ReactElement<any, any> }) => {
  return (
    <HeaderTooltip
      title={
        <Stack gap={1}>
          The method used to wipe the data from a data-bearing asset. We follow NIST standards for data sanitization.
          <Link color="#fff" href="https://example.com" target="_blank">
            Learn more
          </Link>
        </Stack>
      }
    >
      {children}
    </HeaderTooltip>
  );
};

const FinalDispositionTooltip = ({ children }: { children: React.ReactElement<any, any> }) => {
  return (
    <HeaderTooltip
      title={
        <Stack gap={1}>
          Final disposition describes the outcome of our processing. Assets are either repurposed and sold for a second
          life, parted for use in repairs, or recycled by R2 certified vendors to prevent environmental harm.
          <Link color="#fff" href="https://example.com" target="_blank">
            Learn more
          </Link>
        </Stack>
      }
    >
      {children}
    </HeaderTooltip>
  );
};

const assetStatusStyle = (status: string) => {
  if (status == "Available") {
    return { borderRadius: "100%", background: theme.palette.success.light };
  } else if (status == "Enrolled") {
    return { borderRadius: "100%", background: theme.palette.error.light };
  } else if (status == "Checked Out") {
    return { borderRadius: "100%", background: theme.palette.primary.light };
  } else if (status == "Pending Pickup") {
    return { borderRadius: "100%", background: theme.palette.secondary.main };
  } else if (status == "Verifying Release") {
    return { borderRadius: "100%", background: theme.palette.warning.main };
  } else if (status == "Retired") {
    return { borderRadius: "100%", background: "#bcc2be" };
  } else if (status == "Repurposed") {
    return { borderRadius: "100%", background: theme.palette.primary.dark };
  } else if (status == "Recycled") {
    return { borderRadius: "100%", background: "#bcc2be" };
  } else {
    return { borderRadius: "100%", background: theme.palette.warning.light };
  }
};

export function AssetsGrid({
  replace,
  assets,
  setSelectedAssetIds,
  selectedAssetIds,
  isLoading,
  isError,
  meta,
  refetch,
  setSelectAllFlag,
  selectAllFlag,
  assetsTab,
  setSecurityLocksOpen,
  setRetiredAssetOpen,
  getAllSerialsNumbers,
  fieldsToHide,
}: AssetGridProps) {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [headerSelectAll, setHeaderSelectAll] = useState(false);
  const apiRef = useGridApiRef();

  const { enabled: improveAssetDataEnabled } = useFeatureFlag("improve_asset_data");

  const handleSelectAllChange = useCallback(
    (selectedAll: boolean) => {
      if (!selectedAll && selectAllFlag) setSelectAllFlag?.(false);
      setHeaderSelectAll(selectedAll);
    },
    [selectAllFlag, pageNumber, apiRef]
  );

  useEffect(() => {
    if (meta.totalPages > 1) {
      return apiRef.current.subscribeEvent(
        "headerSelectionCheckboxChange",
        (a: GridHeaderSelectionCheckboxParams, event) => {
          event.defaultMuiPrevented = true;
          handleSelectAllChange(a.value);
        }
      );
    }
  }, [meta.totalPages, apiRef]);

  useEffect(() => {
    setHeaderSelectAll(false);
  }, [assets]);

  useEffect(() => {
    replace("page[number]", (pageNumber + 1).toString());
  }, [pageNumber, replace]);

  useEffect(() => {
    replace("page[size]", pageSize.toString());
  }, [pageSize, replace]);

  const columns: GridColDef[] = useMemo(() => {
    const baseColumns = [
      {
        field: "actions",
        headerName: "ACTIONS",
        renderCell: (params: GridRenderCellParams<Asset>) => {
          return <ActionCell asset={params.row} refetch={refetch} />;
        },

        renderHeader: () => (
          <AssetsDataGridActions
            selectedAssetIds={selectedAssetIds}
            assetsTab={assetsTab}
            setSecurityLocksOpen={setSecurityLocksOpen}
            setRetiredAssetOpen={setRetiredAssetOpen}
            getAllSerialsNumbers={getAllSerialsNumbers}
            selectAllFlag={selectAllFlag}
          />
        ),
        sortable: false,
        hideable: false,
        width: 150,
      },
      {
        field: "status",
        headerName: "STATUS",
        width: 150,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const asset = params.row;

          return (
            <Box display="flex" alignItems="center" gap={1}>
              {asset.status || "Unknown"}
              <Box flexShrink={0} width={8} height={8} sx={assetStatusStyle(asset.status)}></Box>
            </Box>
          );
        },
      },
      {
        field: "orderNumber",
        headerName: "PICKUP ID",
        width: 110,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup?.orderNumber}
            </Box>
          );
        },
      },
      {
        field: "virtualPickup",
        headerName: "PICKUP TYPE",
        width: 135,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup?.virtualPickup ? (
                <Chip label={"Remote Retrieval"}></Chip>
              ) : (
                <Chip label={"Onsite Pickup"} sx={{ backgroundColor: grey[100] }}></Chip>
              )}
            </Box>
          );
        },
      },
      {
        field: "scheduledAt",
        headerName: "RETIRED DATE",
        width: 175,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {formatDate(params.row.pickup?.scheduledAt)}
            </Box>
          );
        },
      },
      {
        field: "state",
        headerName: "PICKUP STATUS",
        width: 175,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup && <PickupStatus pickup={params.row.pickup} />}
            </Box>
          );
        },
      },
      {
        field: "address",
        headerName: "ADDRESS",
        width: 175,
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup.state === "processing" ? "-" : params.row.location?.address}
            </Box>
          );
        },
      },
      {
        field: "assignedTo",
        headerName: "ASSIGNED TO",
        sortable: false,
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          const asset = params.row;

          return (
            <Box display="flex" alignItems="center" gap={1}>
              {asset.person?.name}
            </Box>
          );
        },
      },
      {
        field: "deviceType",
        headerName: "TYPE",
        sortable: false,
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup.state === "processing" ? "-" : params.row.deviceType}
            </Box>
          );
        },
      },
      !improveAssetDataEnabled && {
        field: "manufacturer",
        headerName: "MANUFACTURER",
        sortable: false,
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup.state === "processing" ? "-" : params.row.manufacturer}
            </Box>
          );
        },
      },
      improveAssetDataEnabled && {
        field: "manufacturer",
        headerName: "MANUFACTURER",
        sortable: false,
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          const titleizedValue = params.value.slice(0, 1).toUpperCase() + params.value.slice(1);
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup.state === "processing" ? "-" : titleizedValue}
            </Box>
          );
        },
      },
      !improveAssetDataEnabled && {
        field: "serialNumber",
        headerName: "SERIAL",
        sortable: false,
        width: 175,
      },
      improveAssetDataEnabled && {
        field: "serialNumber",
        headerName: "SERIAL",
        sortable: false,
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          // if serial == ISER, render "illegible serial"
          const isIllegible = params.value === "ISER";
          const color = isIllegible ? "gray.500" : "text.primary";
          return <Typography color={color}>{params.value === "ISER" ? "Illegible Serial" : params.value}</Typography>;
        },
      },
      !improveAssetDataEnabled && {
        field: "model",
        headerName: "MODEL",
        sortable: false,
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup.state === "processing" ? "-" : params.row.model}
            </Box>
          );
        },
      },
      improveAssetDataEnabled && {
        field: "model",
        headerName: "MODEL",
        sortable: false,
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          let modelValue = params.value ?? "";
          const { deviceType, manufacturer } = params.row;
          if (modelValue.indexOf("*") > -1 || modelValue.trim().length === 0) modelValue = "NA";

          if (manufacturer.toLowerCase() === "apple" && modelValue === "NA") {
            if (deviceType.toLowerCase() === "tablet") modelValue = "iPad";
            else if (deviceType.toLowerCase() === "mobile phone") modelValue = "iPhone";
          }

          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup.state === "processing" ? "-" : modelValue}
            </Box>
          );
        },
      },
      !improveAssetDataEnabled && {
        field: "endOfLifeCondition",
        headerName: "CONDITION",
        sortable: false,
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup.state === "complete" ? params.row.endOfLifeCondition : "-"}
            </Box>
          );
        },
      },
      {
        field: "assetTag",
        headerName: "ASSET TAG",
        sortable: false,
        width: 175,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.pickup.state === "processing" ? "-" : params.row.assetTag}
            </Box>
          );
        },
      },
      improveAssetDataEnabled && {
        field: "cosmeticCondition",
        headerName: "COSMETIC CONDITION",
        sortable: false,
        width: 225,
        renderHeader: () => (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography>COSMETIC CONDITION</Typography>
            <CosmeticConditionTooltip>
              <Help fontSize="small" sx={{ color: "primary.main" }} />
            </CosmeticConditionTooltip>
          </Stack>
        ),
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              {params.row.cosmeticCondition ? params.row.cosmeticCondition : "-"}
            </Box>
          );
        },
      },
      improveAssetDataEnabled && {
        field: "functionalityFaults",
        headerName: "FUNCTIONALITY FAULTS",
        sortable: false,
        width: 240,
        renderHeader: () => (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography>FUNCTIONALITY FAULTS</Typography>
            <FunctionalityFaultTooltip>
              <Help fontSize="small" sx={{ color: "primary.main" }} />
            </FunctionalityFaultTooltip>
          </Stack>
        ),
      },
      improveAssetDataEnabled && {
        field: "dataSanitization",
        headerName: "DATA SANITIZATION",
        sortable: false,
        width: 215,
        renderHeader: () => (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography>DATA SANITIZATION</Typography>
            <DataSanitizationTooltip>
              <Help fontSize="small" sx={{ color: "primary.main" }} />
            </DataSanitizationTooltip>
          </Stack>
        ),
      },
      improveAssetDataEnabled && {
        field: "finalDisposition",
        headerName: "FINAL DISPOSITION",
        sortable: false,
        width: 205,
        renderHeader: () => (
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography>FINAL DISPOSITION</Typography>
            <FinalDispositionTooltip>
              <Help fontSize="small" sx={{ color: "primary.main" }} />
            </FinalDispositionTooltip>
          </Stack>
        ),
        renderCell: ({ row: { finalDisposition } }: GridRenderCellParams) => (
          <Box display="flex" alignItems="center" gap={1}>
            {finalDisposition === "Repurposed" && (
              <Avatar
                sx={{
                  height: "30px",
                  width: "30px",
                  bgcolor: "rgba(93, 148, 135, 0.1)",
                  color: "rgba(93, 148, 135, 1)",
                }}
              >
                <Public sx={{ fontSize: "20px" }} />
              </Avatar>
            )}
            {finalDisposition === "Parted" && (
              <Avatar
                sx={{
                  height: "30px",
                  width: "30px",
                  bgcolor: "rgba(93, 148, 135, 0.1)",
                  color: "rgba(93, 148, 135, 1)",
                }}
              >
                <Handyman sx={{ fontSize: "20px" }} />
              </Avatar>
            )}
            {finalDisposition === "Recycled" && (
              <Avatar
                sx={{
                  height: "30px",
                  width: "30px",
                  bgcolor: "rgba(242, 160, 43, 0.1)",
                  color: "rgba(242, 160, 43, 1)",
                }}
              >
                <Recycling sx={{ fontSize: "20px" }} />
              </Avatar>
            )}
            {finalDisposition ?? "-"}
          </Box>
        ),
      },
      {
        field: "quantity",
        headerName: "QUANTITY",
        sortable: false,
        width: 100,
      },
    ].filter(Boolean) as GridColDef[];
    return baseColumns;
  }, [selectedAssetIds, getAllSerialsNumbers, improveAssetDataEnabled]);
  if (isError) {
    return (
      <Box
        sx={{
          border: "1px solid",
          borderColor: theme.palette.divider,
          background: "white",
          minHeight: "500px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Something went wrong
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      {headerSelectAll ? (
        <SelectAllAssetsBanner
          selectedAssetIds={selectedAssetIds}
          setSelectedAssetIds={setSelectedAssetIds}
          setSelectAllFlag={setSelectAllFlag}
          selectAllFlag={selectAllFlag}
          setHeaderSelectAll={setHeaderSelectAll}
          meta={meta}
        />
      ) : null}
      <DataGridPro
        sx={{ height: "80vh", backgroundColor: "#fff" }}
        apiRef={apiRef}
        paginationMode="server"
        pagination
        rows={assets}
        columns={columns}
        disableColumnFilter
        pageSize={pageSize || 25}
        rowCount={meta.count}
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selectedAssetIds}
        onSelectionModelChange={setSelectedAssetIds}
        rowsPerPageOptions={[5, 10, 25, 50]}
        page={pageNumber}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns
              assignedTo: !fieldsToHide.includes("assignedTo"),
              orderNumber: !fieldsToHide.includes("orderNumber"),
              scheduledAt: !fieldsToHide.includes("scheduledAt"),
              address: !fieldsToHide.includes("address"),
              state: !fieldsToHide.includes("state"),
              endOfLifeCondition: !fieldsToHide.includes("endOfLifeCondition"),
              quantity: !fieldsToHide.includes("quantity"),
              assetTag: !fieldsToHide.includes("assetTag"),
            },
          },
          pinnedColumns: { right: ["actions"] },
        }}
        onPageChange={(page) => {
          setPageNumber(page);
        }}
        onPageSizeChange={(n) => {
          setPageSize(n);
        }}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: CustomToolbar,
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No assets matching your filters
            </Stack>
          ),
        }}
        componentsProps={{ toolbar: { count: meta.count } }}
      />
      {isLoading && (
        <Box paddingTop={5}>
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
          <Skeleton variant="text" height={75} />
        </Box>
      )}
    </Box>
  );
}
