import { useContext, useState } from "react";
import { Box, Button, ButtonGroup, FormControl, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { InputBase, InputLabel } from "components/common/Input";
import { useFormik } from "formik";
import useOrganization from "hooks/organizations/useOrganization";
import useCurrentUserPermission from "hooks/useCurrentUserPermission";
import useSubscriptions from "hooks/companySettings/useSubscriptions";

import theme from "lib/theme";
import airbase from "./airbase.png";
import { OrganizationConfigurationContext } from "hooks/useOrganizationConfiguration";
import { formatDate } from "utils/helpers";
import { Modal } from "components/common/Modal";
import { ArrowDropDown, ControlPoint, CreditCard, CreditScore } from "@mui/icons-material";
import ActivityTable from "./ActivityTable";
import NewPaymentMethod from "./newPaymentMethod";
import { usePaymentMethods } from "hooks/paymentMethods/usePaymentMethods";
import PlanFeatureWrapper from "components/PlanFeatureWrapper";

function PaymentMethod({ paymentMethod, deletePaymentMethod, setDefaultPaymentMethod }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <ButtonGroup
        variant="contained"
        disableElevation
        sx={{
          background: "white",
          borderRadius: 1,
          py: 2,
          border: `1px solid ${paymentMethod.active ? theme.palette.primary.main : theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            borderRight: `1px solid ${paymentMethod.active ? theme.palette.primary.main : theme.palette.divider}`,
            color: paymentMethod.active && theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1.5,
            px: 3,
          }}
        >
          {paymentMethod.active ? <CreditScore /> : <CreditCard />}
          <span>{paymentMethod.cardBrand}</span>
          <span>****</span>
          {paymentMethod.lastDigits}
        </Box>
        <Button size="small" variant="text" onClick={handleClick}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setDefaultPaymentMethod(paymentMethod);
            handleClose();
          }}
        >
          Make Default
        </MenuItem>
        {!paymentMethod.active && (
          <MenuItem
            onClick={() => {
              deletePaymentMethod(paymentMethod);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

function PaymentInfo() {
  const { organization, updateOrganization } = useOrganization();
  const { hasPermission } = useCurrentUserPermission();
  const [cpOpen, setCpOpen] = useState(false);
  const [newCreditCardOpen, setNewCreditCardOpen] = useState(false);
  const { requestPlanCancellation } = useSubscriptions();

  const organizationConfiguration = useContext(OrganizationConfigurationContext);
  const formik = useFormik({
    initialValues: organization,
    enableReinitialize: true,
    onSubmit: (values) => values && updateOrganization(values),
  });

  const renewalDate = () => {
    if (organizationConfiguration?.organizationPlan?.plan) {
      return formatDate(organizationConfiguration.organizationPlan.subscription.expiredAt);
    }
  };

  const cancelPlan = () => {
    if (organizationConfiguration?.organizationPlan?.organizationId) {
      requestPlanCancellation(String(organizationConfiguration.organizationPlan.subscription.id));
      setCpOpen(false);
    }
  };

  const { paymentMethods, setDefaultPaymentMethod, deletePaymentMethod } = usePaymentMethods();

  return (
    <div>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 4 }}>
        <Box>
          <Box mt={4}>
            <Typography variant="h6" fontWeight="bold">
              Accounting Point of Contact:
            </Typography>

            <Box mt={3}>
              <form onSubmit={formik.handleSubmit}>
                <Box
                  position="relative"
                  paddingTop={5}
                  paddingX={5}
                  paddingBottom={2}
                  sx={{
                    background: "white",
                    borderRadius: 1,
                    border: `1px solid ${theme.palette.divider}`,
                    minHeight: "105px",
                  }}
                >
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="accountingPointOfContactEmail">Email</InputLabel>
                    <InputBase
                      id="accountingPointOfContactEmail"
                      helperText="They’ll receive an email to be onboarded onto airbase shortly."
                      {...formik.getFieldProps("accountingPointOfContactEmail")}
                    />
                  </FormControl>
                  <img
                    src={airbase}
                    style={{
                      position: "absolute",
                      right: 40,
                      top: 25,
                    }}
                  />
                  <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                    <Tooltip
                      title={
                        hasPermission("companyInfo")
                          ? ""
                          : "You do not have permissions for this action, please contact your administrator for permission."
                      }
                    >
                      <span>
                        <Button type="submit" disabled={!hasPermission("companyInfo") || !formik.dirty}>
                          Save
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant="h6" fontWeight="bold">
            Billing History
          </Typography>
          <Box
            sx={{
              mt: 3,
              background: "white",
              borderRadius: 1,
              border: `1px solid ${theme.palette.divider}`,
              minHeight: "105px",
            }}
          >
            <ActivityTable />
          </Box>
        </Box>
      </Box>

      <PlanFeatureWrapper
        feature="asset_retrieval"
        enabledElement={
          <Box mt={3}>
            <Typography variant="h6" fontWeight="bold">
              Your Saved Credit Cards:
            </Typography>

            <Box display="flex" mt={3} gap={2} flexWrap="wrap">
              {paymentMethods &&
                paymentMethods.map((paymentMethod: any) => {
                  return (
                    <PaymentMethod
                      paymentMethod={paymentMethod}
                      deletePaymentMethod={deletePaymentMethod}
                      setDefaultPaymentMethod={setDefaultPaymentMethod}
                    />
                  );
                })}
              <Button variant="outlined" color="primary" sx={{ py: 2 }} onClick={() => setNewCreditCardOpen(true)}>
                <ControlPoint sx={{ mr: 1 }} />
                add new credit card
              </Button>
            </Box>
            {newCreditCardOpen && <NewPaymentMethod />}
          </Box>
        }
        disabledElement={<></>}
        grandfatheredElement={
          <Box mt={3}>
            <Typography variant="h6" fontWeight="bold">
              Your Saved Credit Cards:
            </Typography>

            <Box display="flex" mt={3} gap={2} flexWrap="wrap">
              {paymentMethods &&
                paymentMethods.map((paymentMethod: any) => {
                  return (
                    <PaymentMethod
                      paymentMethod={paymentMethod}
                      deletePaymentMethod={deletePaymentMethod}
                      setDefaultPaymentMethod={setDefaultPaymentMethod}
                    />
                  );
                })}
              <Button variant="outlined" color="primary" sx={{ py: 2 }} onClick={() => setNewCreditCardOpen(true)}>
                <ControlPoint sx={{ mr: 1 }} />
                add new credit card
              </Button>
            </Box>
            {newCreditCardOpen && <NewPaymentMethod />}
          </Box>
        }
      />

      <Modal open={cpOpen} setOpen={setCpOpen} width={540}>
        <Box>
          <Box display="flex" flexDirection="column" justifyItems="center" alignItems="center">
            <img src={require("assets/images/logo.png")} width="60px" />
            <Typography fontSize="24px" fontWeight="800">
              Are you sure you want to cancel your plan?
            </Typography>
            <Typography align="center">
              We’re sad to see you go! You’ll continue to receive our Pro benefits until the next billing cycle before
              being switched back to our base plan.
            </Typography>
            <Box sx={{ mt: 2, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
              <Button color="secondary" variant="contained" size="small" disableElevation onClick={() => cancelPlan()}>
                Yes, Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                disableElevation
                onClick={() => setCpOpen(false)}
              >
                No, don't cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default PaymentInfo;
