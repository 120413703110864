import { AssetRetrievalPricing } from "../hooks/assetRetrievals/useAssetRetrieval";
import { AssetRetrieval } from "hooks/assetRetrievals/useAssetRetrieval";
import { AssetRetrievalBulkOrderDetails } from "types/assetRetrieval";

export const orderTotal = (
  insuranceOption: string,
  pricing: AssetRetrievalPricing | null = null,
  assetRetrieval: AssetRetrieval
) => {
  if (assetRetrieval.virtualPickup) return 0;
  return pricing
    ? (parseInt(insuranceOption) + 10 + 70) * (1 - pricing.discountPercentage / 100.0)
    : (parseInt(insuranceOption) + 10 + 70) * 0.6;
};

export const orderDiscountAmount = (
  insuranceOption: string,
  pricing: AssetRetrievalPricing | null = null,
  assetRetrieval: AssetRetrieval | AssetRetrievalBulkOrderDetails
) => {
  if (assetRetrieval.virtualPickup) return 90;
  return pricing
    ? (parseInt(insuranceOption) + 10 + 70) * (pricing.discountPercentage / 100.0)
    : (parseInt(insuranceOption) + 10 + 70) * 0.4;
};

export const bulkOrderDiscountAmount = (
  quantity: number,
  insuranceOption: string,
  pricing: AssetRetrievalPricing | null = null,
  assetRetrieval: AssetRetrieval | AssetRetrievalBulkOrderDetails
) => {
  if (assetRetrieval.virtualPickup) return 90;
  const insuranceCost = parseInt(insuranceOption);
  const baseCostPerItem = 10 + 70;
  const discountMultiplier = pricing ? pricing.discountPercentage / 100.0 : 0.4;

  return quantity * baseCostPerItem * discountMultiplier + insuranceCost;
};

export const bulkOrderTotal = (
  quantity: number,
  insuranceOption: string,
  pricing: AssetRetrievalPricing | null = null,
  assetRetrieval: AssetRetrievalBulkOrderDetails
) => {
  if (assetRetrieval.virtualPickup) return 0;
  const insuranceCost = parseInt(insuranceOption);
  const baseCostPerItem = 10 + 70;
  const totalMultiplier = pricing ? 1 - pricing.discountPercentage / 100.0 : 0.6;

  return quantity * baseCostPerItem * totalMultiplier + insuranceCost;
};
